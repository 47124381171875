import axios from 'axios'

const URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8080/v1'
    : 'https://neopro-server.rj.r.appspot.com/v1'

const axiosInstance = axios.create({
  baseURL: URL,
  timeout: 20000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':
      'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
  }
})

axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('@NeoPro:token')
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})

// Caso haja alteração no token do usuário, é feito um refresh e atualiza o mesmo sem deslogar o vendedor
axiosInstance.interceptors.response.use(
  response => {
    if (response && response.headers['set-token']) {
      localStorage.setItem('@NeoPro:token', response.headers['set-token'])
    }

    return response
  },
  error => {
    // Handle users on the blacklist (disabled accounts), sign them out, and prevent them from logging in
    if (
      error.response.status === 401 &&
      error.response.data.error.code === 11
    ) {
      localStorage.clear()
    }

    return Promise.reject(error)
  }
)

const login = (login, password) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/auth/account`, { login, password })
      .then(response => {
        if (response.status === 200) {
          resolve(response.data.token)
        } else {
          reject(response.data.error)
        }
      })
      .catch(err => reject(err))
  })
}

export default {
  login,
  axios: axiosInstance
}
