// React
import { Dispatch, SetStateAction, Fragment, useState } from 'react'
// Components
import Tooltip from 'components/Tooltip'
import CalendarWithRangeDates from '../../../components/CalendarWithRangeDates'
// MaterialUI
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Grid
} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { formatPrice, formatToPercentage } from '../../../utils/format'

// Libs
import { format, getMonth } from 'date-fns'
import 'react-day-picker/lib/style.css'

// Style
import { Calendar, HeaderStore, VisiblePerfectScrollbar } from './styles'
import { IMonthReport, IRange } from 'store/modules/report/types'
import { IStore } from 'store/modules/store/types'
import { TableHeadersProps } from '../Reports'

const useStyles = makeStyles(theme => ({
  content: {
    position: 'relative',

    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 25
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  column: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  sticky: {
    position: 'sticky',
    left: 0,
    background: 'white'
  }
}))

interface TableHeaderProps {
  id: string
  label: string
  minWidth: number
}

interface ICommissionArray {
  type?: string
  sectionIndex: number
  value: number | 'Abaixo da meta'
  start: string
  end: string
}

interface IBonusArray {
  sectionIndex: number
  value: number
}

interface ISellerTooltipInfos {
  DSR?: string | undefined
  Complemento?: string | undefined
  'Comiss\u00E3o base'?: string | undefined
  Comissão?: string | undefined
  Bônus?: string | undefined
  Piso?: string | undefined
}

interface ReportsTableProps {
  reports: IMonthReport | null
  store: IStore
  requestReports: (range: IRange) => void
  tableHeaders: TableHeadersProps
  tableOtherHeaders: TableHeaderProps[]
  range: IRange
  openCalendar: boolean
  setOpenCalendar: Dispatch<SetStateAction<boolean>>
}

export default function ReportsTable({
  reports,
  store,
  requestReports,
  tableHeaders,
  tableOtherHeaders,
  range,
  openCalendar,
  setOpenCalendar
}: ReportsTableProps) {
  const [selectedRow, setSelectedRow] = useState(-1)
  const [selectedOtherInputsRow, setSelectedOtherInputsRow] = useState(-1)

  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  })

  const disableValues =
    range.from && range.to && getMonth(range.from) !== getMonth(range.to)

  const handleDayClick = (calendarRange: IRange) => {
    if (calendarRange.from && calendarRange.to) {
      requestReports(calendarRange)
    }
  }

  // Dia do mês com dois dígitos
  const getDayMonth = (date: string) => {
    return format(new Date(date), 'dd')
  }

  // Monta o tooltip de Comissões caso seja comissão por período
  const createPeriodCommissionTooltip = (
    commission: ICommissionArray,
    index: number,
    sectionIndex: number,
    arrayLength: number
  ) => {
    return (
      <Fragment key={commission.start}>
        <ol
          style={{
            listStyleType: 'none',
            ...(index === arrayLength - 1 && {
              display: 'block',
              marginBottom: '6px'
            })
          }}
        >
          <li style={{ marginLeft: '8px' }}>
            <strong>
              {`• Período ${sectionIndex} (${getDayMonth(
                commission.start
              )} a ${getDayMonth(commission.end)})`}
            </strong>
            <ol
              style={{
                listStyleType: 'none'
              }}
            >
              <li style={{ marginLeft: '16px' }}>
                {`•  ${commission.type ? `${commission.type}:` : ''} ${
                  typeof commission.value === 'number'
                    ? formatPrice(commission.value)
                    : commission.value
                }`}
              </li>
            </ol>
          </li>
        </ol>
      </Fragment>
    )
  }

  // Monta o tooltip de Bonus caso seja comissão por período
  const createPeriodBonusTooltip = (bonus: number, sectionIndex: number) => (
    <Fragment key={sectionIndex}>
      <ol style={{ listStyleType: 'none' }}>
        <li style={{ marginLeft: '8px' }}>
          <strong>• Período {sectionIndex}: </strong> {formatPrice(bonus)}
        </li>
      </ol>
    </Fragment>
  )

  const maxDateAllowed = new Date()
  maxDateAllowed.setMonth(maxDateAllowed.getMonth() + (!!reports ? 1 : 0))
  maxDateAllowed.setDate(1)
  maxDateAllowed.setDate(maxDateAllowed.getDate() - 1)

  const maxLengthName = 25

  const formatSellerName = (name: string) => {
    if (name.length > maxLengthName) {
      return name.slice(0, maxLengthName) + '...'
    }

    return name
  }

  return (
    <div className={classes.container}>
      <Card>
        <Grid item>
          {openCalendar && (
            <Calendar isDesktop={isDesktop}>
              <CalendarWithRangeDates
                openCalendar={openCalendar}
                setOpenCalendar={setOpenCalendar}
                handleDayClick={handleDayClick}
                range={range}
                maxDate={maxDateAllowed}
              />
            </Calendar>
          )}
        </Grid>
        <CardContent className={classes.content}>
          {store?.otherInputs && (
            <HeaderStore>Desempenho vendedores - Todas as lojas</HeaderStore>
          )}
          <VisiblePerfectScrollbar>
            <div className={classes.inner}>
              {disableValues && (
                <Tooltip
                  id={`view-goal-modal`}
                  content={
                    <p>
                      Informações disponíveis apenas para datas do mesmo mês
                    </p>
                  }
                  place='bottom'
                />
              )}
              <Table
                stickyHeader
                onMouseLeave={() => {
                  if (selectedRow > -1) {
                    setSelectedRow(-1)
                  }
                }}
              >
                <TableHead>
                  <TableRow
                    onMouseEnter={() => {
                      if (selectedRow > -1) {
                        setSelectedRow(-1)
                      }
                    }}
                  >
                    {tableHeaders.map((header, index) => (
                      <TableCell
                        key={header.id}
                        style={{
                          minWidth: header.minWidth,
                          ...(header.label === '%' && {
                            textAlign: 'end'
                          }),
                          ...(index === 0 && { zIndex: 10 })
                        }}
                      >
                        {store?.config.sellersSortRanking > 0 ? (
                          header.id === 'goalPercent' ? (
                            <TableSortLabel
                              active
                              direction={'asc'}
                              style={{ cursor: 'text' }}
                            >
                              {header.label}
                            </TableSortLabel>
                          ) : (
                            header.label
                          )
                        ) : header.label === 'Faturamento' ? (
                          <TableSortLabel
                            active
                            direction={'asc'}
                            style={{ cursor: 'text' }}
                          >
                            {header.label}
                          </TableSortLabel>
                        ) : (
                          header.label
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!store?.otherInputs && reports?.store && (
                    <>
                      <TableRow
                        hover
                        selected
                        onMouseEnter={() => {
                          if (selectedRow > -1) {
                            setSelectedRow(-1)
                          }
                        }}
                      >
                        <TableCell
                          className={classes.sticky}
                          style={{ backgroundColor: '#fdf8ec' }}
                        >
                          <b>Loja</b>
                        </TableCell>
                        <TableCell>
                          <b>{formatPrice(reports.store.total.sold)}</b>
                        </TableCell>

                        <TableCell
                          data-tip
                          data-for={`view-goal-modal`}
                          style={{ paddingRight: 0, textAlign: 'end' }}
                        >
                          <b>
                            {disableValues
                              ? '-'
                              : formatToPercentage(
                                  (reports.store.total.sold /
                                    reports.store.metric.acDayGoal) *
                                    100
                                )}
                          </b>
                        </TableCell>
                        <TableCell data-tip data-for={`view-goal-modal`}>
                          <b>
                            {disableValues
                              ? '-'
                              : formatPrice(reports.store.metric.acDayGoal)}
                          </b>
                        </TableCell>
                        <TableCell
                          data-tip
                          data-for={`view-goal-modal`}
                          style={{ paddingRight: 0, textAlign: 'end' }}
                        >
                          <b>
                            {disableValues
                              ? '-'
                              : formatToPercentage(
                                  (reports.store.total.sold /
                                    reports.store.metric.mainGoal) *
                                    100
                                )}
                          </b>
                        </TableCell>
                        <TableCell data-tip data-for={`view-goal-modal`}>
                          <b>
                            {disableValues
                              ? '-'
                              : formatPrice(reports.store.metric.mainGoal)}
                          </b>
                        </TableCell>
                        <TableCell data-tip data-for={`view-goal-modal`}>
                          -
                        </TableCell>
                        <TableCell data-tip data-for={`view-goal-modal`}>
                          <b>
                            {disableValues
                              ? '-'
                              : formatPrice(reports.store.projection.sold)}
                          </b>
                        </TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>
                          <b>{reports.store.total.sales.toFixed(0)}</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {formatPrice(
                              reports.store.total.sold /
                                reports.store.total.sales
                            )}
                          </b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {reports.store.total.sales &&
                            reports.store.total.sales !== 0
                              ? (
                                  reports.store.total.items /
                                  reports.store.total.sales
                                ).toFixed(2)
                              : '0,00'}
                          </b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {formatPrice(
                              reports.store.total.sold /
                                reports.store.total.items
                            )}
                          </b>
                        </TableCell>
                        <TableCell>
                          <b>{reports.store.total.items.toFixed(0)}</b>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {reports && reports.sellers.length > 0 ? (
                    [...reports.sellers]
                      .sort((a, b) =>
                        store?.config.sellersSortRanking > 0
                          ? (b.total.sold / b.metric.acDayGoal) * 100 -
                            (a.total.sold / a.metric.acDayGoal) * 100
                          : b.total.sold - a.total.sold
                      )
                      .map((seller, index) => {
                        const {
                          complement,
                          dsr,
                          sumCommission,
                          sumSectionsCommissions,
                          salary,
                          bonus,
                          commission,
                          fixedCommission,
                          sections
                        } = seller.commission

                        const isPeriodGoal = sections && sections.length > 0
                        const commissionArray: ICommissionArray[] = []
                        const bonusArray: IBonusArray[] = []

                        if (isPeriodGoal && sections) {
                          sections.forEach(section => {
                            if (section.bonus) {
                              bonusArray.push({
                                value: section.bonus.value,
                                sectionIndex: section.sectionIndex
                              })
                            }
                            if (section.start && section.end) {
                              let commissionValue: 'Abaixo da meta' | number =
                                section.commission?.value ?? 0
                              if (section.fixedCommission) {
                                commissionValue = section.fixedCommission.value
                              } else if (
                                // Caso o sumCommission seja 0 e NÃO tem fixedCommission a gente mostra Abaixo da Meta
                                section.sumCommission === 0 &&
                                !section.fixedCommission
                              ) {
                                commissionValue = 'Abaixo da meta'
                              }

                              commissionArray.push({
                                type:
                                  section.fixedCommission && 'Comissão Base',
                                sectionIndex: section.sectionIndex + 1,
                                value: commissionValue,
                                start: section.start,
                                end: section.end
                              })
                            }
                          })
                        }

                        const sellerTooltipInfos: ISellerTooltipInfos = {
                          ...(isPeriodGoal
                            ? // Objeto caso sejam vários períodos
                              {
                                ...(dsr && { DSR: formatPrice(dsr) }),
                                ...(salary && { Piso: formatPrice(salary) }),
                                ...(complement && {
                                  Complemento: formatPrice(complement)
                                })
                              }
                            : // Objeto caso seja período único
                              {
                                ...(fixedCommission?.value && {
                                  'Comissão base': formatPrice(
                                    fixedCommission.value
                                  )
                                }),
                                ...(commission?.value && {
                                  Comissão: formatPrice(commission.value)
                                }),
                                ...(dsr && { DSR: formatPrice(dsr) }),
                                ...(salary && { Piso: formatPrice(salary) }),
                                ...(complement && {
                                  Complemento: formatPrice(complement)
                                }),
                                ...(bonus?.value && {
                                  Bônus: formatPrice(bonus.value)
                                })
                              })
                        }

                        const {
                          complement: projectionComplement,
                          dsr: projectionDsr,
                          sumCommission: projectionSumCommission,
                          sumSectionsCommissions:
                            projectionSumSectionsCommissions,
                          salary: projectionSalary,
                          bonus: projectionBonus,
                          commission: projectionCommission,
                          fixedCommission: projectionFixedCommission,
                          sections: projectionSections
                        } = seller?.projection?.commission

                        const projectionCommissionArray: ICommissionArray[] = []
                        const projectionBonusArray: IBonusArray[] = []

                        if (isPeriodGoal && projectionSections) {
                          projectionSections.forEach(section => {
                            if (section.bonus) {
                              projectionBonusArray.push({
                                value: section.bonus.value,
                                sectionIndex: section.sectionIndex
                              })
                            }
                            if (section.start && section.end) {
                              let commissionValue: 'Abaixo da meta' | number =
                                section.commission?.value ?? 0

                              if (section.fixedCommission) {
                                commissionValue = section.fixedCommission.value
                              } else if (
                                // Caso o sumCommission seja 0 e NÃO tem fixedCommission a gente mostra Abaixo da Meta
                                section.sumCommission === 0 &&
                                !section.fixedCommission
                              ) {
                                commissionValue = 'Abaixo da meta'
                              }

                              projectionCommissionArray.push({
                                type:
                                  section.fixedCommission && 'Comissão Base',
                                sectionIndex: section.sectionIndex + 1,
                                value: commissionValue,
                                start: section.start,
                                end: section.end
                              })
                            }
                          })
                        }

                        const sellerProjectionTooltipInfos = {
                          ...(isPeriodGoal
                            ? // Objeto caso sejam vários períodos
                              {
                                ...(projectionDsr && {
                                  DSR: formatPrice(projectionDsr)
                                }),
                                ...(projectionSalary && {
                                  Piso: formatPrice(projectionSalary)
                                }),
                                ...(projectionComplement && {
                                  Complemento: formatPrice(projectionComplement)
                                })
                              }
                            : // Objeto caso seja período único
                              {
                                ...(projectionFixedCommission?.value && {
                                  'Comissão base': formatPrice(
                                    projectionFixedCommission.value
                                  )
                                }),
                                ...(projectionCommission?.value && {
                                  Comissão: formatPrice(
                                    projectionCommission.value
                                  )
                                }),
                                ...(projectionDsr && {
                                  DSR: formatPrice(projectionDsr)
                                }),
                                ...(projectionSalary && {
                                  Piso: formatPrice(projectionSalary)
                                }),

                                ...(projectionComplement && {
                                  Complemento: formatPrice(projectionComplement)
                                }),
                                ...(projectionBonus?.value && {
                                  Bônus: formatPrice(projectionBonus.value)
                                })
                              })
                        }

                        const sellerName = formatSellerName(
                          seller.name.complete
                        )

                        return (
                          <TableRow
                            hover
                            key={seller._id}
                            selected={false}
                            onMouseEnter={() => setSelectedRow(index)}
                          >
                            <TableCell
                              className={classes.sticky}
                              style={{
                                ...(index === selectedRow && {
                                  backgroundColor: '#f5f5f5'
                                }),
                                whiteSpace: 'nowrap'
                              }}
                            >
                              {seller.active ? (
                                sellerName
                              ) : (
                                <div
                                  aria-label='view'
                                  color='secondary'
                                  data-tip
                                  data-for={`view-activeGoal-modal`}
                                >
                                  <s>{sellerName}</s>
                                </div>
                              )}
                            </TableCell>
                            <TableCell>
                              {formatPrice(seller.total.sold)}
                            </TableCell>

                            <TableCell
                              data-tip
                              data-for={`view-goal-modal`}
                              style={{
                                color: (value =>
                                  value >= 100
                                    ? '#27ae60'
                                    : value >= 80
                                    ? '#f39c12'
                                    : '#e74c3c')(
                                  (seller.total.sold /
                                    seller.metric.acDayGoal || 0) * 100
                                ),
                                paddingRight: 0,
                                textAlign: 'end'
                              }}
                            >
                              {disableValues ||
                              !(seller.active && seller.metric.acDayGoal > 0)
                                ? '-'
                                : formatToPercentage(
                                    (seller.total.sold /
                                      seller.metric.acDayGoal) *
                                      100
                                  )}
                            </TableCell>
                            <TableCell data-tip data-for={`view-goal-modal`}>
                              {disableValues
                                ? '-'
                                : formatPrice(seller.metric.acDayGoal)}
                            </TableCell>
                            <TableCell
                              data-tip
                              data-for={`view-goal-modal`}
                              style={{
                                color: (value =>
                                  value >= 100
                                    ? '#27ae60'
                                    : value >= 80
                                    ? '#f39c12'
                                    : '#e74c3c')(
                                  seller.metric.goalValue &&
                                    seller.metric.goalValue !== 0
                                    ? (seller.total.sold /
                                        seller.metric.goalValue || 0) * 100
                                    : 0
                                ),
                                paddingRight: 0,
                                textAlign: 'end'
                              }}
                            >
                              {disableValues ||
                              !(seller.active && seller.metric.goalValue > 0)
                                ? '-'
                                : formatToPercentage(
                                    (seller.total.sold /
                                      seller.metric.goalValue) *
                                      100
                                  )}
                            </TableCell>
                            <TableCell data-tip data-for={`view-goal-modal`}>
                              {disableValues
                                ? '-'
                                : formatPrice(seller.metric.goalValue)}
                            </TableCell>
                            <TableCell data-tip data-for={`view-goal-modal`}>
                              {disableValues
                                ? '-'
                                : seller.projection.goal.name}
                            </TableCell>
                            <TableCell data-tip data-for={`view-goal-modal`}>
                              <b>
                                {disableValues
                                  ? '-'
                                  : formatPrice(seller.projection.sold)}
                              </b>
                            </TableCell>
                            <TableCell data-tip data-for={`view-goal-modal`}>
                              <Tooltip
                                disabledValues={disableValues}
                                id={`current-remuneration-${seller._id}`}
                                content={
                                  <div>
                                    <strong
                                      style={{
                                        display: 'block',
                                        marginBottom: '6px',
                                        fontSize: '14px'
                                      }}
                                    >
                                      Remuneração Atual
                                    </strong>
                                    {commissionArray.length > 0 && (
                                      <>
                                        <strong>Comissão:</strong>
                                        {commissionArray.map(
                                          (commission, index) =>
                                            createPeriodCommissionTooltip(
                                              commission,
                                              index,
                                              commission.sectionIndex,
                                              commissionArray.length
                                            )
                                        )}
                                      </>
                                    )}
                                    {Object.entries(sellerTooltipInfos).map(
                                      ([key, value]) => (
                                        <p
                                          key={key}
                                          style={{ marginBottom: '2px' }}
                                        >
                                          <strong>{key}: </strong> {value}
                                        </p>
                                      )
                                    )}

                                    {bonusArray.length > 0 && (
                                      <>
                                        <strong>Bônus:</strong>
                                        {bonusArray.map(bonus =>
                                          createPeriodBonusTooltip(
                                            bonus.value,
                                            bonus.sectionIndex + 1
                                          )
                                        )}
                                      </>
                                    )}

                                    <p style={{ marginTop: '6px' }}>
                                      <strong>Total: </strong>
                                      {formatPrice(
                                        (sumCommission ||
                                          sumSectionsCommissions) ??
                                          0
                                      )}
                                    </p>
                                  </div>
                                }
                                place='bottom'
                              />

                              {disableValues
                                ? '-'
                                : formatPrice(
                                    (sumCommission || sumSectionsCommissions) ??
                                      0
                                  )}

                              {!disableValues && (
                                <ErrorOutlineIcon
                                  data-tip
                                  data-for={`current-remuneration-${seller._id}`}
                                  style={{
                                    color: '#C4C4C4',
                                    fontSize: 20,
                                    marginLeft: 5,
                                    marginBottom: -5
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell data-tip data-for={`view-goal-modal`}>
                              <Tooltip
                                disabledValues={disableValues}
                                id={`projection-remuneration-${seller._id}`}
                                content={
                                  <div>
                                    <strong
                                      style={{
                                        display: 'block',
                                        marginBottom: '6px',
                                        fontSize: '14px'
                                      }}
                                    >
                                      Projeção de Remuneração <br />
                                    </strong>

                                    {projectionCommissionArray.length > 0 && (
                                      <>
                                        <strong>Comissão:</strong>
                                        {projectionCommissionArray.map(
                                          (commission, index) =>
                                            createPeriodCommissionTooltip(
                                              commission,
                                              index,
                                              index + 1,
                                              projectionCommissionArray.length
                                            )
                                        )}
                                      </>
                                    )}

                                    {Object.entries(
                                      sellerProjectionTooltipInfos
                                    ).map(([key, value]) => (
                                      <p
                                        key={key}
                                        style={{ marginBottom: '2px' }}
                                      >
                                        <strong>{key}: </strong> {value}
                                        <br />
                                      </p>
                                    ))}

                                    {projectionBonusArray.length > 0 && (
                                      <>
                                        <strong>Bônus:</strong>
                                        {projectionBonusArray.map(bonus =>
                                          createPeriodBonusTooltip(
                                            bonus.value,
                                            bonus.sectionIndex + 1
                                          )
                                        )}
                                      </>
                                    )}

                                    <p style={{ marginTop: '6px' }}>
                                      <strong>Total: </strong>
                                      {formatPrice(
                                        (projectionSumCommission ||
                                          projectionSumSectionsCommissions) ??
                                          0
                                      )}
                                    </p>
                                  </div>
                                }
                                place='bottom'
                              />
                              <b>
                                {disableValues
                                  ? '-'
                                  : formatPrice(
                                      (projectionSumCommission ||
                                        projectionSumSectionsCommissions) ??
                                        0
                                    )}

                                {!disableValues && (
                                  <ErrorOutlineIcon
                                    data-tip
                                    data-for={`projection-remuneration-${seller._id}`}
                                    style={{
                                      color: '#C4C4C4',
                                      fontSize: 20,
                                      marginLeft: 5,
                                      marginBottom: -5
                                    }}
                                  />
                                )}
                              </b>
                            </TableCell>
                            <TableCell>
                              {seller.total.sales.toFixed(0)}
                            </TableCell>
                            <TableCell>
                              {formatPrice(
                                seller.total.sold / seller.total.sales
                              )}
                            </TableCell>
                            <TableCell>
                              {seller.total.sales !== 0
                                ? (
                                    seller.total.items / seller.total.sales
                                  ).toFixed(2)
                                : 0}
                            </TableCell>
                            <TableCell>
                              {formatPrice(
                                seller.total.sold / seller.total.items
                              )}
                            </TableCell>
                            <TableCell>
                              {seller.total.items.toFixed(0)}
                            </TableCell>
                          </TableRow>
                        )
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10} style={{ textAlign: 'center' }}>
                        <Typography variant='subtitle2'>
                          Sem relatório para apresentar.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {!store?.otherInputs && reports?.other && (
                    <TableRow
                      hover
                      onMouseEnter={() => {
                        if (selectedRow > -1) {
                          setSelectedRow(-1)
                        }
                      }}
                    >
                      <TableCell>Outras vendas</TableCell>
                      <TableCell>{formatPrice(reports.other.sold)}</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>{reports.other.sales.toFixed(0)}</TableCell>
                      <TableCell>
                        {formatPrice(reports.other.sold / reports.other.sales)}
                      </TableCell>
                      <TableCell>
                        {reports.other.sales !== 0
                          ? (reports.other.items / reports.other.sales)
                              .toFixed(2)
                              .replace('.', ',')
                          : 0}
                      </TableCell>
                      <TableCell>
                        {formatPrice(reports.other.sold / reports.other.items)}
                      </TableCell>
                      <TableCell>{reports.other.items.toFixed(0)}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </VisiblePerfectScrollbar>
        </CardContent>
      </Card>

      <Card>
        {store?.otherInputs && reports?.otherInputs && (
          <>
            <HeaderStore>Desempenho Loja - {store.name}</HeaderStore>
            <VisiblePerfectScrollbar>
              <div className={classes.inner}>
                {disableValues && (
                  <Tooltip
                    id={`view-goal-modal`}
                    content={
                      <p>Informação disponível para datas do mesmo mês</p>
                    }
                    place='bottom'
                  />
                )}
                <Table
                  stickyHeader
                  onMouseLeave={() => {
                    if (selectedOtherInputsRow > -1) {
                      setSelectedOtherInputsRow(-1)
                    }
                  }}
                >
                  <TableHead>
                    <TableRow>
                      {tableOtherHeaders.map(header => (
                        <TableCell
                          key={header.id}
                          style={{
                            minWidth: header.minWidth,
                            ...(header.label === '%' && {
                              textAlign: 'end'
                            })
                          }}
                        >
                          {header.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reports?.otherInputs && (
                      <>
                        <TableRow
                          hover
                          selected
                          onMouseEnter={() => {
                            if (selectedOtherInputsRow > -1) {
                              setSelectedOtherInputsRow(-1)
                            }
                          }}
                        >
                          <TableCell
                            className={classes.sticky}
                            style={{ backgroundColor: '#fdf8ec' }}
                          >
                            <b>Loja</b>
                          </TableCell>
                          <TableCell>
                            <b>
                              {formatPrice(
                                reports.otherInputs.store.total.sold
                              )}
                            </b>
                          </TableCell>
                          <TableCell
                            data-tip
                            data-for={`view-goal-modal`}
                            style={{ paddingRight: 0, textAlign: 'end' }}
                          >
                            {disableValues ? (
                              '-'
                            ) : (
                              <b>
                                {formatToPercentage(
                                  (reports.otherInputs.store.total.sold /
                                    reports.store.metric.acDayGoal) *
                                    100
                                )}
                              </b>
                            )}
                          </TableCell>
                          <TableCell data-tip data-for={`view-goal-modal`}>
                            <b>
                              {disableValues
                                ? '-'
                                : formatPrice(reports.store.metric.acDayGoal)}
                            </b>
                          </TableCell>
                          <TableCell
                            data-tip
                            data-for={`view-goal-modal`}
                            style={{ paddingRight: 0, textAlign: 'end' }}
                          >
                            {disableValues ? (
                              '-'
                            ) : (
                              <b>
                                {formatToPercentage(
                                  (reports.otherInputs.store.total.sold /
                                    reports.store.metric.mainGoal) *
                                    100
                                )}
                              </b>
                            )}
                          </TableCell>
                          <TableCell data-tip data-for={`view-goal-modal`}>
                            <b>
                              {disableValues
                                ? '-'
                                : formatPrice(reports.store.metric.mainGoal)}
                            </b>
                          </TableCell>
                          <TableCell
                            data-tip
                            data-for={`view-goal-modal`}
                            style={{ textAlign: 'center' }}
                          >
                            <b>-</b>
                          </TableCell>

                          <TableCell data-tip data-for={`view-goal-modal`}>
                            <b>
                              {disableValues
                                ? '-'
                                : formatPrice(
                                    reports.otherInputs.store.soldProjection
                                  )}
                            </b>
                          </TableCell>
                          <TableCell>
                            <b>
                              {reports.otherInputs.store.total.sales.toFixed(0)}
                            </b>
                          </TableCell>
                          <TableCell>
                            <b>
                              {formatPrice(
                                reports.otherInputs.store.total.sold /
                                  reports.otherInputs.store.total.sales
                              )}
                            </b>
                          </TableCell>
                          <TableCell>
                            <b>
                              {(reports.otherInputs.store.total.sales !== 0
                                ? reports.otherInputs.store.total.items /
                                  reports.otherInputs.store.total.sales
                                : 0
                              ).toFixed(2)}
                            </b>
                          </TableCell>
                          <TableCell>
                            <b>
                              {formatPrice(
                                reports.otherInputs.store.total.sold /
                                  reports.otherInputs.store.total.items
                              )}
                            </b>
                          </TableCell>
                          <TableCell>
                            <b>
                              {reports.otherInputs.store.total.items.toFixed(0)}
                            </b>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {reports?.sellers.length > 0 ? (
                      [...reports.sellers]
                        .sort((a, b) => b.total.sold - a.total.sold)
                        .map((seller, index) => {
                          const sellerName = formatSellerName(
                            seller.name.complete
                          )
                          return (
                            <TableRow
                              hover
                              key={seller._id}
                              selected={false}
                              onMouseEnter={() =>
                                setSelectedOtherInputsRow(index)
                              }
                            >
                              <TableCell
                                className={classes.sticky}
                                style={{
                                  ...(index === selectedOtherInputsRow && {
                                    backgroundColor: '#f5f5f5'
                                  }),
                                  whiteSpace: 'nowrap',
                                  zIndex: 10
                                }}
                              >
                                {seller.active ? (
                                  sellerName
                                ) : (
                                  <div
                                    aria-label='view'
                                    color='secondary'
                                    data-tip
                                    data-for={`view-activeGoal-modal`}
                                  >
                                    <s>{sellerName}</s>
                                    <Tooltip
                                      id={`view-activeGoal-modal`}
                                      content={
                                        <p>
                                          Vendedor(a) não está participando da
                                          meta
                                        </p>
                                      }
                                      place='right'
                                    />
                                  </div>
                                )}
                              </TableCell>
                              <TableCell>
                                {formatPrice(seller.otherInputs?.sold ?? 0)}
                              </TableCell>
                              <TableCell
                                data-tip
                                data-for={`view-goal-modal`}
                                style={{
                                  color: (value =>
                                    value >= 100
                                      ? '#27ae60'
                                      : value >= 80
                                      ? '#f39c12'
                                      : '#e74c3c')(
                                    ((seller.otherInputs?.sold ?? 0) /
                                      seller.metric.acDayGoal) *
                                      100 || 0
                                  ),
                                  opacity: 0.45,
                                  paddingRight: 0,
                                  textAlign: 'end'
                                }}
                              >
                                {disableValues ||
                                !(seller.active && seller.metric.acDayGoal > 0)
                                  ? '-'
                                  : formatToPercentage(
                                      ((seller?.otherInputs?.sold ?? 0) /
                                        seller.metric.acDayGoal) *
                                        100
                                    )}
                              </TableCell>
                              <TableCell
                                style={{ opacity: 0.45 }}
                                data-tip
                                data-for={`view-goal-modal`}
                              >
                                {disableValues
                                  ? '-'
                                  : formatPrice(seller.metric.acDayGoal)}
                              </TableCell>
                              <TableCell
                                data-tip
                                data-for={`view-goal-modal`}
                                style={{
                                  color: (value =>
                                    value >= 100
                                      ? '#27ae60'
                                      : value >= 80
                                      ? '#f39c12'
                                      : '#e74c3c')(
                                    ((seller.otherInputs?.sold ?? 0) /
                                      seller.metric.goalValue) *
                                      100 || 0
                                  ),
                                  opacity: 0.45,
                                  paddingRight: 0,
                                  textAlign: 'end'
                                }}
                              >
                                {disableValues ||
                                !(seller.active && seller.metric.goalValue > 0)
                                  ? '-'
                                  : formatToPercentage(
                                      ((seller.otherInputs?.sold ?? 0) /
                                        seller.metric.goalValue) *
                                        100
                                    )}
                              </TableCell>
                              <TableCell
                                style={{ opacity: 0.45 }}
                                data-tip
                                data-for={`view-goal-modal`}
                              >
                                {disableValues
                                  ? '-'
                                  : formatPrice(seller.metric.goalValue)}
                              </TableCell>
                              <TableCell
                                style={{ opacity: 0.45, textAlign: 'center' }}
                                data-tip
                                data-for={`view-goal-modal`}
                              >
                                -
                              </TableCell>
                              <TableCell
                                style={{ opacity: 0.45 }}
                                data-tip
                                data-for={`view-goal-modal`}
                              >
                                {disableValues
                                  ? '-'
                                  : formatPrice(seller.projection.sold)}
                              </TableCell>
                              <TableCell>
                                {seller.otherInputs?.sales.toFixed(0) ?? 0}
                              </TableCell>
                              <TableCell>
                                {formatPrice(
                                  (seller.otherInputs?.sold ?? 0) /
                                    (seller.otherInputs?.sales ?? 0)
                                )}
                              </TableCell>
                              <TableCell>
                                {seller.otherInputs?.sales &&
                                seller.otherInputs?.sales !== 0
                                  ? (
                                      (seller.otherInputs?.items ?? 0) /
                                      seller.otherInputs?.sales
                                    ).toFixed(2)
                                  : 0}
                              </TableCell>
                              <TableCell>
                                {formatPrice(
                                  (seller.otherInputs?.sold ?? 0) /
                                    (seller.otherInputs?.items ?? 0)
                                )}
                              </TableCell>
                              <TableCell>
                                {seller.otherInputs?.items.toFixed(0) || 0}
                              </TableCell>
                            </TableRow>
                          )
                        })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={10} style={{ textAlign: 'center' }}>
                          <Typography variant='subtitle2'>
                            Sem relatório para apresentar.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {reports?.otherInputs && (
                      // Outras vendas
                      <TableRow
                        hover
                        onMouseEnter={() => {
                          if (selectedOtherInputsRow > -1) {
                            setSelectedOtherInputsRow(-1)
                          }
                        }}
                      >
                        <TableCell>Outras vendas</TableCell>
                        <TableCell>
                          {formatPrice(reports.otherInputs.other.sold)}
                        </TableCell>
                        <TableCell style={{ opacity: 0.45 }}>-</TableCell>
                        <TableCell style={{ opacity: 0.45 }}>-</TableCell>
                        <TableCell style={{ opacity: 0.45 }}>-</TableCell>
                        <TableCell style={{ opacity: 0.45 }}>-</TableCell>
                        <TableCell style={{ opacity: 0.45 }}>-</TableCell>
                        <TableCell style={{ opacity: 0.45 }}>-</TableCell>
                        <TableCell>
                          {reports.otherInputs.other.sales.toFixed(0)}
                        </TableCell>
                        <TableCell>
                          {formatPrice(
                            reports.otherInputs.other.sold /
                              reports.otherInputs.other.sales
                          )}
                        </TableCell>
                        <TableCell>
                          {reports.otherInputs.other.sales &&
                          reports.otherInputs.other.sales !== 0
                            ? (
                                reports.otherInputs.other.items /
                                reports.otherInputs.other.sales
                              ).toFixed(2)
                            : '0,00'}
                        </TableCell>
                        <TableCell>
                          {formatPrice(
                            reports.otherInputs.other.sold /
                              reports.otherInputs.other.items
                          )}
                        </TableCell>
                        <TableCell>
                          {reports.otherInputs.other.items.toFixed(0)}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </VisiblePerfectScrollbar>
          </>
        )}
      </Card>
    </div>
  )
}
